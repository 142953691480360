<template>
  <div class="mb-6 mt-4">
    <v-layout row pa-2>
      <v-row>
        <v-col class="successPlot" ml-4>
          <v-flex
            v-for="suggested in suggestedFields"
            :key="suggested.key"
            xs12
            sm6
            lg4
            xl3
            pr-4
          >
            <component
              :is="suggested.type"
              v-if="suggested.type === 'radio'"
              v-model="suggestedMetrics[suggested.key]"
              :append-text="suggested.unit"
              :label="suggested.label"
              :disabled="suggested.isReadonly"
              :field-key="suggested.key"
              :items="suggested.items"
            />
            <component
              :is="suggested.type"
              v-else
              :value="suggestedMetricsValue(suggested.key)"
              :append-text="suggested.unit"
              :label="suggested.label"
              :disabled="suggested.isReadonly"
            />
          </v-flex>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="suggestPlot">
          <v-flex
            v-for="(field, index) in successFields"
            :key="index"
            xs12
            sm6
            lg4
            xl3
            pr-4
          >
            <component
              :is="field.type"
              v-if="field.type === 'radio'"
              v-model="successMetrics[field.key]"
              :append-text="field.unit"
              :label="field.label"
              :disabled="field.isReadonly"
              :field-key="field.key"
              :items="field.items"
            />
            <component
              :is="field.type"
              v-else
              :value="successMetricsValue(field.key)"
              :append-text="field.unit"
              :label="field.label"
              :disabled="field.isReadonly"
            />
          </v-flex>
        </v-col>
      </v-row>
    </v-layout>
  </div>
</template>

<script>
import { get } from "lodash";
import Number from "@/components/form/NumberInput";
import NumberInput from "@/components/form/NumberInput";
import Dropdown from "@/components/form/Dropdown";
import Radio from "@/components/form/Radio";

export default {
  name: "Metrics",

  components: { Number, NumberInput, Dropdown, Radio },

  props: {
    schema: { type: Array, required: true, default: () => ({}) },
    successMetrics: { type: Object, required: true, default: () => [] },
    suggestedMetrics: { type: Object, required: true, default: () => ({}) },
  },
  data() {
    return {
      successFields: [],
      suggestedFields: [],
      rounded: [],
    };
  },
  mounted() {
    this.successFields = this.schema;
    this.suggestedFields = this.schema;
  },
  methods: {
    successMetricsValue(key) {
      return get(this.successMetrics, key);
    },
    suggestedMetricsValue(key) {
      return get(this.suggestedMetrics, key);
    },
  },
};
</script>
<style scoped>
.successPlot {
  border: 3px solid #ef5151;
}
.flex.sm6 {
  max-width: 100%;
}
.suggestPlot {
  border: 3px solid #0035c7;
}
/* .successPlot[data-v-2e65b939] {
  margin-top: 24px;
  max-width: 95%;
  height: 150px;
  margin-left: 10px;
}
.suggestPlot[data-v-2e65b939] {
  max-width: 96%;
  height: 150px;
  margin-left: 10px;
} */
.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 2px;
}
</style>
