<template>
<v-container>
  <v-flex>
    <!-- <div class="title">
      Target Attainment
    </div> -->
    
      <!-- <v-layout> -->
        <v-row>
          <template
            v-for="field in fields"
          >
            <v-col 
              :key="field.key"
              cols="12"
              sm="12" 
              md="12" 
              lg="6"
              xl="6"
            >
              <component
                :is="field.type"
                :key="field.key"
                :graph-title="field.label"
                :suggested-metric="suggestedMetric(field.key)"
                :success-metric="successMetric(field.key)"
              />
            </v-col>
          </template>
        </v-row>
      <!-- </v-layout> -->
   
  </v-flex>
   </v-container>
</template>
<script>
import Attainment from "@/components/patient/Attainment";
import { get } from "lodash";
// import schema from "../../assets/event";
export default {
  name: "TargetAttainment",
  components: { Attainment },
  props: {
    schema: { type: Array, required: true,  default: () => [] },
    suggestedMetrics: { type: Object, required: true, default: () => ({})  },
    successMetrics: { type: Object, required: true, default: () => ({}) },
  },
  data() {
    return {
      fields: []
    };
  },
  mounted() {
    this.fields = this.schema;
  },
  methods: {
    suggestedMetric(key) {
      return get(this.suggestedMetrics, key, 0);
    },
    successMetric(key) {
      return get(this.successMetrics, key, 0);
    }
  }
};
</script>
