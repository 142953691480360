import config from '../config/instance';
/**
 * get local storage method
 * @param {String} key
 */
const getLocalStoragevalue = (key) => localStorage.getItem(key);

/** patient url endpoints */
let ehrUrl = '';
ehrUrl = config.ehrUrl;
// if (config.ehrUrl) {
//   ehrUrl = config.ehrUrl;
// } else {
//   ehrUrl = 'ehr';
// }
/**
 * set local storage method
 * @param {String} key
 * @param {String} value
 */
const setLocalStoragevalue = (key, value) => localStorage.setItem(key, value);

/**
 * service urls
 */
const url = {
  /** endpoints */
  getAllPatient: `${ehrUrl}/all/patients`,
  getAllTreatment: 'treatment/all',
  getAllCondition: 'condition/all',
  getAllFiles: `${ehrUrl}/uploaded/files`,
  saveFile: `${ehrUrl}/upload`,
  login: '/auth/login',
  logout: 'auth/logout',
  forgot_pass: 'auth/forgot-password',
  change_pass: 'users/change-password',
  acceptedTermsAndCondition: 'users/condition-accepted',
  getCondition: 'condition',
  schema: 'condition/modules/schema',
  getTreatment: `/${ehrUrl}/treatment`,
  getTimeline: `/${ehrUrl}/getTimeline`,
  getTimelineEntry: `/${ehrUrl}/getTimelineEntry`,
  recommendDosingSchedule: `/${ehrUrl}/recommendDosingSchedule`,
  updateDosingSchedule: `/${ehrUrl}/updateDosingSchedule`,
  getPaitentDetails: `/${ehrUrl}/getPatientDetails`,
};

export default {
  getLocalStoragevalue, 
  setLocalStoragevalue,
  url,
};
